import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils/grid';

export const search = css`
  padding: calc(var(--unit) * 12) 0;

  @media ${breakpoints.large} {
    padding: calc(var(--unit) * 30) 0;
  }
`;

export const searchGrid = css`
  @media ${breakpoints.large} {
    display: grid;
    grid-gap: calc(var(--unit) * 8);
    grid-template-columns: 4fr 8fr;
  }
`;

export const searchHeading = css`
  ${searchGrid};
  margin-bottom: calc(var(--unit) * 10);

  @media ${breakpoints.large} {
    margin-bottom: calc(var(--unit) * 20);
  }

  h1 {
    margin: 0;
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxLarge);
    font-family: var(--font-family-secondary);
    font-weight: normal;
  }
`;

export const searchInputWrapper = css`
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  padding: 0 calc(var(--unit) * 4) 0 0;
  height: 52px;
`;

export const searchInput = css`
  background: white;
  padding: calc(var(--unit) * 4) 0 calc(var(--unit) * 4) calc(var(--unit) * 5);
  font-weight: bold;
`;

export const resultsWrapper = css`
  ${searchGrid};
`;

export const result = css`
  min-height: 0;

  @media ${breakpoints.medium} {
    display: grid;
    grid-gap: calc(var(--unit) * 8);
    ${grid.template.cols2};
  }
`;

export const opening = css`
  min-height: 320px;
  margin-bottom: ${spacing(32)};
  padding: ${spacing(20)};

  :last-of-type {
    margin-bottom: 0;
  }

  @media ${breakpoints.large} {
    min-height: 280px;
    margin-bottom: 0;
    padding: ${spacing(40)};
  }
`;

export const noResults = css`
  display: flex;
  justify-content: center;

  div {
    max-width: 416px;
  }

  h3 {
    font-size: var(--font-size-xLarge);
    line-height: var(--line-height-xLarge);
    font-family: var(--font-family-secondary);
    font-weight: normal;
    margin: calc(var(--unit) * 10) 0;
  }

  p {
    color: hsl(var(--color-grayscale-6));
  }
`;
